<template>
  <div class="jumbotron">
    <div class="container">
      <h3 class="display-4">Welcome to White Oak Church</h3>
      <img src="outside1.jpg" alt="Photo of outside of church" class="img-fluid" />
    </div>
  </div>
  <!-- <VbsRegistration></VbsRegistration> -->
  <!-- <PhotoSignup></PhotoSignup> -->
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2>Events</h2>
        <h5>Ladies Fellowship Soup Social, Wednesday, October 23, 6:30 p.m</h5>
        <p>
          With the weather turning colder and Thanksgiving and Christmas right around the corner, ladies (young and old
          alike) are invited to join us for a warm soup get-together. Jess Bruckhart will be sharing thoughts on
          “Intentionality in the Holidays”.
        </p>
        <p>
          Sign up using this <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSet82E5pVwLN6snUNCbGSMi8ZTsJxmlpKd-OwQq9ogui_zBeQ/viewform?usp=sf_link">link</a>.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h2>Sermon Streaming</h2>
        <p>
          With your computer or smartphone you can listen in and also see anything that's projected on the screens in
          the
          auditorium. Since some of the announcements mention members or missionaries by name we
          require a password to access this content. Contact one of our members to get the current password.
        </p>
        <p>
          <router-link to="/sermon-streaming" class="btn btn-secondary">Sermon Streaming</router-link>
        </p>
      </div>
      <div class="col-md-6">
        <h2>Visit Us</h2>
        <p>We'd love to have you join us for one of our worship services. Click below for details on times & location.
        </p>
        <p>
          <router-link to="/contact" class="btn btn-secondary">Find Us</router-link>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>News</h2>
        <p>The White Oak congregation recently celebrated its 250th anniversary. Watch the highlight reel here:</p>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" loading="lazy" title="Highlight Reel | 250th Anniversary | Part 1 of 9"
            src="https://www.youtube.com/embed/ni_bP_racSg?list=PLMNmwGzcWyevetYhMHGw0qU8muYqo8j-I" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <a href="https://tithe.ly/give_new/www/#/tithely/give-one-time/7222167">
          <button class="tithely-give-btn"
            style="background-color: #00DB72;font-family: inherit;font-weight: bold;font-size: 19px; padding: 15px 70px; border-radius: 4px; cursor: pointer; background-image: none; color: white; text-shadow: none; display: inline-block; float: none; border: none;"
            data-church-id="7222167">Give</button>
        </a>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import { auth } from "../firebaseConfig";

export default {
  name: "DashboardComponent",

  setup () {
    const store = useStore();

    auth.onAuthStateChanged((user) => {
      store.dispatch("fetchUser", user);
    });

    const user = computed(() => {
      return store.getters.user;
    });

    return { user };
  },
};
</script>